<template>
  <div id="withdrawal">
    <div class="withdrawal text-center">
      <card-header ref="header" :node="headerTitle" />
      <div id="content-scroll" class="content-scroll">
        <div class="content">
          <img
            src="../assets/image/credit-card.svg"
            class="content__card-img"
          />
          <div class="content__bank-name f-w3">
            {{ user_info.bank_name }} 銀行 {{ user_info.branch_name }} 支店
          </div>
          <div class="content__number f-w6" v-if="user_info.account_number">
            {{ user_info.account_number }}
          </div>
          <div
            class="content__attention f-w6"
            @click="$refs.attention.openModal()"
          >
            注意事項
          </div>
          <div
            class="checkbox d-flex justify-content-center align-items-center"
            @click="status = !status"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              :class="[status ? 'checkbox__input--actived' : 'checkbox__input']"
            >
              <b-icon icon="check" class="icon" scale="1.3"></b-icon>
            </div>
            <div class="checkbox__label f-w3">同意する</div>
          </div>
          <button
            @click="withDraPg()"
            :class="[status ? 'content__apply--active' : 'content__apply']"
            :disabled="!status"
          >
            申請する
          </button>
          <button
            class="content__change-account"
            @click="nextBankAccountRegistration()"
          >
            銀行口座を変更する
          </button>
          <ModalLayout ref="attention" :defaultStyle="false">
            <Attention />
          </ModalLayout>
        </div>
      </div>
      <card-footer ref="footer" />
    </div>
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader.vue";
import CardFooter from "@/components/CardFooter.vue";
import Attention from "@/components/Attention.vue";
import { mapGetters } from "vuex";

export default {
  name: "PointHistoryReceiption",
  components: {
    "card-header": CardHeader,
    "card-footer": CardFooter,
    Attention
  },
  metaInfo() {
    return {
      title: "出金申請",
      titleTemplate: "%s | PreGo"
    };
  },
  props: ["id"],
  data() {
    return {
      headerTitle: {
        text: "出金申請",
        isShowTitle: true,
        isShowLogo: false
      },
      status: false,
      prevRoute: null
    };
  },
  computed: {
    ...mapGetters({
      user_info: "auth/user"
    })
  },
  created() {
    if (!this.user_info || this.user_info.user_type !== 2) {
      this.$router.push({ name: "403" });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  methods: {
    nextBankAccountRegistration() {
      let genderUrl = "MenMyPage";
      if (this.user_info.sex === 2) {
        genderUrl = "CastMyPage";
      }
      this.$root.$refs.loading.start();
      this.$router.push({ name: genderUrl + "BankAccountRegistration" });
      this.$root.$refs.loading.finish();
    },

    // pay point for all
    async withDraPg() {
      await this.$store
        .dispatch("pointHistory/withDrawal", {
          amount: localStorage.getItem("point")
        })
        .then(() => {
          this.$toast("申請が完了しました。", "通知", "success");
          localStorage.removeItem("point");
          this.$root.$refs.loading.start();
          if (this.user_info.sex === 1) {
            this.$router.push({ name: "MenMyPage" });
          } else {
            this.$router.push({ name: "CastMyPage" });
          }
          this.$root.$refs.loading.finish();
        })
        .catch(error => {
          if (error.response.data.error.code === "4041") {
            this.$toast("申請できませんでした。", "不合格", "danger");
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
@import "@/assets/scss/men/withdrawal.scss";

.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 152px);
  // }
}
</style>
